@import '../../global.css';
.container {
    margin: 4%;
    background-color: var(--background-color) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
    padding: 2rem;
    margin-bottom: 5rem;
}

.albumContainer {
    border: 1px solid white;
    text-decoration: none;
    background-color: inherit;
}

.albumContainer:hover {
    box-shadow:
        rgba(251, 245, 249, 0.4) 5px 5px,
        rgba(150, 132, 143, 0.3) 10px 10px,
        rgba(164, 162, 163, 0.2) 15px 15px,
        rgba(37, 31, 35, 0.1) 20px 20px,
        rgba(246, 227, 239, 0.05) 25px 25px;
}

.description {
    text-decoration: none;
}