.container {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--background-color) !important;
    color: var(--text-color);
    border-top: 1px solid var(--highlight-color);
    flex-wrap: wrap;
    gap: 1rem;
}

.madeBy a {
    background: linear-gradient(to right, rgb(26, 31, 31), rgb(227, 249, 249)),
        linear-gradient(to right, rgb(2, 2, 2), rgb(97, 88, 94), rgb(3, 3, 3));
    background-size:
        100% 3px,
        0 3px;
    background-position:
        100% 100%,
        0 100%;
    background-repeat: no-repeat;
    transition: background-size 300ms;
    color: var(--text-color);
    padding-top: 1rem;
}

.madeBy a:hover {
    background-size:
        0 4px,
        100% 5px;
    text-shadow: var(--hightlight-color) 5px 0 10px;
    padding-bottom: 1rem;
}
