@import '../../global.css';

.container {
    border-bottom: 1px solid var(--highlight-color);
}

.logo {
    height: 60px;
    margin-top: 1rem;
}

.linkContainer {
    display: flex;
}

.linkBox {
    padding: 0.5em;
    color: var(--text-color);
}

.link {
    color: var(--text-color);
    text-decoration: none;
    margin: 1rem;
}

.link:hover {
    text-shadow: var(--highlight-color) 5px 0 10px;
}

.activeLink {
    display: block;
    color: var(--tertiary-color);
    font-weight: bold;
    text-decoration: underline;
    border-bottom: 1px solid var(--highlight-color);
    box-shadow: var(--highlight-color) 5px 0 10px;
    padding: 0.5rem;
}

