:root {
    --background-color: #212529;
    --background-second-color: #343a40;
    --background-third-color: #495057;
    --primary-color: #6c757d;
    --secondary-color: #adb5bd;
    --tertiary-color: #ced4da;
    --text-color: #dee2e6;
    --text-second-color: #e9ecef;
    --highlight-color: #f8f9fa;

    @font-face {
        font-family: 'OstrichSans-Black';
        src:
            local('OstrichSans-Black'),
            url('../public/fonts/ostrich-sans/OstrichSans-Black.otf')
                format('otf');
        font-weight: normal;
        font-style: normal;
    }
}


/* <svg id="svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="400" height="223.35329341317365" viewBox="0, 0, 400,223.35329341317365"><g id="svgg"><path id="path0" d="M104.246 57.654 C 104.170 57.777,103.365 57.877,102.458 " stroke="none" fill="#6a6a6a" fill-rule="evenodd"></path><path id="path1" d="M159.665 70.559 C 158.927 70.603,158.228 70.734,158.112 70.850 C .844,268.720 164.451,268.534 163.986 C 268.457 163.793,268.409 163.834,268.398 164.102 " stroke="none" fill="#444444" fill-rule="evenodd"></path><path id="path2" d="M98.998 59.385 C 99.000 " stroke="none" fill="#4c4c4c" fill-rule="evenodd"></path><path id="path3" d="M101.453  143.551,41.037 143.163 C 41.713 141.867,41.788 141.684,41.788 141.337 C 41.788 141.147,41.939 140.934,42.123" stroke="none" fill="#050505" fill-rule="evenodd"></path><path id="path4" d="M101.233 58.878 C 101.078 " stroke="none" fill="#3c3c3c" fill-rule="evenodd"></path></g></svg> */