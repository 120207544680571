.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 1%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--background-color);
    object-fit: cover;
}

.modalContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 98%;
    margin: auto;
    border: 1px solid white;
}

.smallScreenContent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 98%;
    margin: auto;
    border: 1px solid white;
}

.imageContainer {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.imageContainer:hover {
    box-shadow:
        rgba(247, 249, 250, 0.3) 0px 0px 25px,
        rgba(233, 236, 239, 0.5) 0px 0px 10px,
        rgba(233, 236, 239, 0.5) 0px 0px 6px,
        rgba(4, 7, 9, 0.17) 0px 0px 10px,
        rgba(222, 226, 230, 0.09) 0px 0px 2px;
}


.arrowBox {
    padding: 2rem;
    background-color: var(--background-color);
    border: none;
}

.arrowForwardBox,
.arrowBackBox {
    display: flex;
}

.arrowIcon {
    position: relative;
    transition: transform 0.3s ease-in-out;
    margin: -15px;
}

.arrowIcon::before,
.arrowIcon::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
}

.arrowIcon::before {
    left: -10px;
}

.arrowIcon::after {
    right: -10px;
}

.arrowBackIcon {
    transform: rotate(-90deg);
}

.arrowForwardIcon {
    transform: rotate(90deg);
}

@keyframes forwardArrowAnimation {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateX(20px);
    }
}

@keyframes backArrowAnimation {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateX(-20px);
    }
}

.arrowForwardBox:hover,
.arrowForwardBox:hover::before,
.arrowForwardBox:hover::after {
    animation: forwardArrowAnimation 2s infinite;
}

.arrowBackBox:hover,
.arrowBackBox:hover::before,
.arrowBackBox:hover::after {
    animation: backArrowAnimation 2s infinite;
    transform: scaleX(1);
}

.arrow {
    height: 50px;
    width: 50px;
}

@media screen and (max-width: 1024px) {
    .arrowBox {
        height: 40%;
        padding: 0.5rem;
    }
    .arrow {
        height: 20px;
        width: 20px;
    }
}
