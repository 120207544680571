.container {
    text-align: center;
    color: var(--text-color);
    justify-content: space-around;
    align-items: center;
    row-gap: 1rem;
    padding: 1rem 0.8rem;
}

.container img {
    background-color: var(--background-color);
}
